<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";

import $ from "jquery";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Tambah Kategori Surat",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Tambah Kategori Surat",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Kategori Surat",
                    href: "/master/Kategori Surat",
                },
                {
                    text: "Tambah Kategori Surat",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            // variable Page
            master_tipe: ['Surat Masuk', 'Surat Keluar'],
            master_surat_keluar: [],
            nama: '',
            tipe: '',
            master_tipe_selected: '',
            master_surat_keluar_selected: '',
            file_surat: '',
        };
    },
    mounted() {
        this.getSuratKeluar();
    },
    methods: {
        resetForm() {
            this.nama = '';
            this.tipe = '';
            this.master_tipe_selected = '';
            this.master_surat_keluar_selected = '';
            this.file_surat = null;
            $("#uploadLoading").html('');
        },
        getSuratKeluar() {
            let self = this;
            self.loadingTable = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/tipe_surat_keluar?status=ENABLE")
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.master_surat_keluar = response_data.list_data.data;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },
        StoreData() {
            let self = this;
            var tipe_id = self.master_surat_keluar_selected?.id;
            if (tipe_id) {
                tipe_id = self.master_surat_keluar_selected?.id;
            } else {
                tipe_id = '';
            }
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var FormData = require("form-data");
            var data = new FormData();
            data.append("nama", self.nama);
            data.append("tipe", self.master_tipe_selected);
            data.append("surat_keluar", tipe_id);
            data.append("file_surat", self.file_surat);

            var config = {
                method: "post",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/kategorisurat/store",
                data: data,
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.code != 200) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            html: response_data.message,
                        });
                    } else {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master kategori surat segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({ name: "all-kategori_surat" });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        uploadFile() {
            let self = this;
            if ($("#upload")[0].files[0]) {
                if ($("#upload")[0].files[0].size < 5000000) {
                    $("#uploadLoading").html(
                        '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
                    );

                    var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#upload")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "api/master/uploadhandle/uploadssurat",
                        headers: {
                            Accept: "application/json",
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        var file_name = response.data.file_name;
                        urlres += dir_name;

                        $("#uploadLoading").html(
                            '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                            urlres +
                            '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
                        );

                        self.file_surat = file_name;
                    }).catch((e) => {
                        Swal.fire({
                            icon: "error",
                            title: "Gagal upload file",
                            text: e.response?.data?.message?.file,
                        });
                    });
                }
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Kategori Surat"
                                        label-for="formrow-nama-Kategori Surat-input">
                                        <b-form-input id="formrow-nama-Kategori Surat-input" type="text"
                                            v-model="nama"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Tipe">
                                        <v-select placeholder="-Pilih Tipe-" :options="master_tipe"
                                            v-model="master_tipe_selected"></v-select>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Surat Keluar">
                                        <v-select placeholder="-Pilih Surat Keluar-" label="nama"
                                            :options="master_surat_keluar"
                                            v-model="master_surat_keluar_selected"></v-select>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="File Surat" accept="application/pdf">
                                        <input type="file" id="upload" @change="uploadFile">
                                    </b-form-group>
                                    <input type="hidden" v-model="file_surat">
                                    <div class="respond-input-file float-left" id="uploadLoading"></div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i>
                                Simpan</b-button>
                            <b-button type="reset" @click="resetForm" variant="danger" class="m-1"><i
                                    class="fa fa-refresh"></i>
                                Reset</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
